import React from "react";
import { ScrollView, View, Text, StyleSheet, Image } from "react-native";

import {
  GTAmericaCompressed,
  greenBrand,
  blackBrand,
  TrailersDemiBold,
  PanamaBold,
  GTAmerica,
} from "../libs/constants";
import { CuratorHeader } from "../components/curator/CuratorHeader";
import { PhoneNumberSignupForm } from "../components/forms/PhoneNumberSignupForm";
import { EventSlice } from "../components/events/EventSlice";
import { RouteComponentProps } from "../navigation";
import { useEffect } from "react";
import { mdBreakpoint } from "../constants";
import { useDimensions } from "../context/DimensionsContext";
import { HoverableLink } from "../components/common/HoverableLink";
import { useThemeState } from "../context/ThemeContext";
import { H1, P } from "@expo/html-elements";
import { TextureUvs } from "pixi.js";

interface Props { }

export const EventsLandingScreen: React.FC<
  RouteComponentProps<{
    isSmsSubscriber: string;
  }>
> = (props: RouteComponentProps<{ isSmsSubscriber: string }>) => {
  document.title = "Events | Happy Medium";
  const scrollRef = React.useRef(null);
  const { width } = useDimensions();
  const { theme } = useThemeState();
  const {
    match: {
      params: { isSmsSubscriber },
    },
  } = props;

  const subscribed = isSmsSubscriber === "subscribed";
  console.log(
    "SMS??",
    isSmsSubscriber,
    isSmsSubscriber === "subscribed",
    subscribed
  );
  const measuringTape = require("@happymedium/components/assets/images/blue-white-measure-tape-long-horizontal.png");
  const styles = StyleSheet.create({
    infoText: {
      fontFamily: GTAmericaCompressed,
      fontWeight: "300",
      fontSize: 24,
      color: blackBrand,
    },
    body: {
      color: theme.foregroundColor,
      fontFamily: GTAmerica,
      fontSize: width < mdBreakpoint ? 14 : 16,
    },
  });

  const mobileEventHeight = 740;
  const desktopEventHeight = 480;

  useEffect(() => {
    if (subscribed) {
      //@ts-ignore
      scrollRef.current!.scrollTo({
        y:
          width < mdBreakpoint
            ? mobileEventHeight * 9.45
            : desktopEventHeight * 9.74,
      });
    }
  }, []);

  return (
    <ScrollView
      style={{
        flex: 1,
        backgroundColor: theme.backgroundColor,
      }}
      ref={scrollRef}
    >
      <CuratorHeader
      // backgroundColor={greenBrand}
      // hideThemePicker={true}
      // logoColor={'black'}
      // linkColor={blackBrand}
      // isRow={true}
      />
      <View
        style={{
          maxWidth: width < mdBreakpoint ? "90%" : 540,
          marginTop: width < mdBreakpoint ? 20 : 60,
          alignSelf: "center",
          alignItems: "flex-start",
          // paddingLeft: 20,
          // paddingRight: 20,
          backgroundColor: theme.backgroundColor,
        }}
      >
        <H1
          style={{
            color: theme.foregroundColor,
            fontFamily: PanamaBold,
            fontWeight: "normal",
            fontSize: width < mdBreakpoint ? 24 : 38,
            letterSpacing: 1.2,
            width: "100%",
            textTransform: "uppercase",
            alignSelf: "flex-start",
          }}
        >
          Classes & Figure Drawing
        </H1>
        {/* <View style={{ height: 20 }} /> */}
        <Text style={[styles.body]}>
          Happy Medium's events are designed for the adult beginner who wants to
          have fun creating instead of consuming for a few hours with zero
          expectation of being "good enough." To learn more, check out our{" "}
          <HoverableLink
            linkUrl="/events/faq"
            linkType="internal"
            textStyle={{ alignSelf: "center" }}
          >
            <Text style={[styles.body]}>event FAQs</Text>
          </HoverableLink>
          .
          <P style={[styles.body, { fontSize: 14, fontWeight: "bold" }]}>
            **We are unable to accomodate refunds on ticket purchases. You must
            notify us at reservations@happy-medium.co at least 24 hours before
            your time slot in order to be eligible to reschedule. Otherwise, the
            full price will be forfeit.**
          </P>
          {`\n\n`}
          Check out our guide to an evening of figure drawing{" "}
          <HoverableLink
            linkUrl="/events/figure-drawing"
            linkType="internal"
            textStyle={{ alignSelf: "center" }}
          >
            <Text style={[styles.body]}>here</Text>
          </HoverableLink>
          !
        </Text>
      </View>
      {!subscribed && (
        <>
          <View style={{ height: 20 }} />
          <View
            style={{
              backgroundColor: theme.foregroundColor,
              paddingTop: 20,
              paddingBottom: 30,
              width: "100vw",
              maxWidth: 584,
              alignSelf: "center",
            }}
          >
            <Text
              style={[
                styles.infoText,
                {
                  fontSize: 22,
                  fontFamily: TrailersDemiBold,
                  textTransform: "uppercase",
                  letterSpacing: 1.4,
                  textAlign: "center",
                  color: theme.backgroundColor,
                },
              ]}
            >
              Sign up to be the first to know about new events
            </Text>
            <View style={{ alignItems: "center", marginTop: 5 }}>
              <PhoneNumberSignupForm />
            </View>
          </View>
        </>
      )}
      <View style={{ width: 30, height: 40 }} />
      <View />

      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Tuesday, October 8th, 2024"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2024-10-08-figure-drawing.png"
        eventSubtitle="with special guest, Laura."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/cN217K5CQ6aa92M390"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Wednesday, October 9th, 2024"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2024-10-09-figure-drawing.png"
        eventSubtitle="with special guest, Ella."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/28o6s4c1e6aadj225O"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="The Art of Watercolor: Still Life"
        eventDate="Sunday, October 13th, 2024"
        eventTime="9:00am - 11:00am"
        eventImgUrl="https://49market.s3.amazonaws.com/2024-10-13-watercolor-still-life.png"
        eventSubtitle="with Claire Wilson."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/5kA5o08P2fKKfrafVJ"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Tuesday, October 15th, 2024"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2024-10-15-figure-drawing.png"
        eventSubtitle="with special guest, Lorelei."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/5kA03G9T66aa4Mw10T"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Wednesday, October 16th, 2024"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2024-10-16-figure-drawing.png"
        eventSubtitle="with special guest, Bola Bee."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/bIY03GaXacyy4MwgZS"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Figure Drawing 101"
        eventDate="Saturday, October 19th, 2024"
        eventTime="9:00am - 11:00am"
        eventImgUrl="https://49market.s3.amazonaws.com/2024-10-19-figure-drawing-101.png"
        eventSubtitle="with Matthieu Dougherty."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/14kdUwfdqeGGdj25hx"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="The Art of Oil Painting"
        eventDate="Sunday, October 20th, 2024"
        eventTime="9:00am - 12:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2024-10-20-oil.png"
        eventSubtitle="with Paul Ferney."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/bIY5o06GUfKKceYh0J"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="The Art of Watercolor: Garden Painting"
        eventDate="Sunday, October 20th, 2024"
        eventTime="9:00am - 11:00am"
        eventImgUrl="https://49market.s3.amazonaws.com/2024-10-20-gp-garden-painting.png"
        eventSubtitle="with Claire Wilson."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/14kdUwe9m6aa6UE6la"
        location="224 Franklin St, Brooklyn, NY 11222"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Tuesday, October 22nd, 2024"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2024-10-22-figure-drawing.png"
        eventSubtitle="with special guest, Shea."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/9AQcQs1mAbuu92M9xM"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Wednesday, October 23rd, 2024"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2024-10-23-figure-drawing.png"
        eventSubtitle="with special guest, Bola Bee."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/8wMg2Efdq1TU92Mh0Y"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="The Art of Collage"
        eventDate="Saturday, October 26th, 2024"
        eventTime="9:00am - 11:00am"
        eventImgUrl="https://49market.s3.amazonaws.com/2024-10-26-collage.png"
        eventSubtitle="with James Gallagher."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/9AQ5o07KY2XY2Eo11M"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Tuesday, October 29th, 2024"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2024-10-29-figure-drawing.png"
        eventSubtitle="with special guest, Ella."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/14kaIkaXa7eebaU4cZ"
        location="49 Market St, NY, NY 10002"
      />
      <EventSlice
        eventTitle="Figure Drawing"
        eventDate="Wednesday, October 30th, 2024"
        eventTime="7:00pm - 9:00pm"
        eventImgUrl="https://49market.s3.amazonaws.com/2024-10-30-figure-drawing.png"
        eventSubtitle="with special guest, Tommi."
        eventUrl="/event/252"
        soldOut={false}
        ticketUrl="https://buy.stripe.com/fZe4jWfdq0PQceYbFs"
        location="49 Market St, NY, NY 10002"
      />

      <View style={{ height: 40 }} />
      <Image
        source={measuringTape}
        style={{
          height: 45,
          width: 450,
          maxWidth: "100vw",
          resizeMode: "contain",
          marginTop: 40,
          paddingBottom: 40,
          alignSelf: "center",
          transform: [{ rotate: "-10deg" }],
          left: 200,
        }}
      />
    </ScrollView>
  );
};

// <EventSlice
//         key={'AOPD_JUL312022'}
//         eventTitle="The Art of Plywood Design"
//         eventDate="Sunday, July 31st, 2022"
//         eventTime="10:00am - 2:00pm"
//         eventImgUrl="https://49market.s3.amazonaws.com/2022-07-31_art-of-plywood-design.png"
//         eventSubtitle="with Rett Carraway."
//         eventUrl="/event/208"
//         soldOut={false}
//         ticketUrl="https://buy.stripe.com/dR63fSd5i5662EodUt"
//       />
